import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { toast } from "react-toastify";
import FullReportPDF from "./FullReportPDF"; 
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import titleInsuranceData from "../utils/title_insurance_data.json";
import { PDFDownloadLink } from "@react-pdf/renderer";

const RequestChangeModal = (props) => {
  const { showModal, hideModal, offer, currentSeller } = props;
  const includedKeys = [
    "bac",
    "licenseNo",
    "offerType",
    "earnestMoney",
    "nonRealtyItem",
    "rightToTerminate",
    "saleOfOtherProperty",
    "bac_type",
    "financingType",
    "numberOfDays",
    "optionFee",
    "brokerageName",
    "titlePolicyExpense",
    "possession",
    "purchasePrice",
    "leasebackDays",
    "documents",
    "buyer",
    "buyerPhoneNumber",
    "financeAmount",
    "closingDate",
    "offer_expire_date"
  ];

  // Helper function to safely render various data types
  const renderValue = (value) => {
    if (value === null || value === undefined) return "N/A";
    if (Array.isArray(value)) {
      // For arrays, join values or format objects
      return value
        .map((item) =>
          typeof item === "object"
            ? Object.entries(item)
                .map(([k, v]) => `${k}: ${v}`)
                .join(", ")
            : item
        )
        .join(" | ");
    }
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value.toString();
  };

  return (
    <>
      <Modal
        className="seller-net-modal-body"
        scrollable={true}
        isOpen={showModal}
        toggle={hideModal}
        size="lg"
      >
        <ModalHeader toggle={hideModal}>Request Change</ModalHeader>
        <ModalBody>
          <Row>
            <p className="seller-net-sheet-description">
              Review the buyer’s offer and suggest updates to negotiate terms that work for you.
            </p>
            <Col className="p-0">
              {offer && (
                <div className="req-change-container">
                  {includedKeys.map((key) => {
                    if (offer.hasOwnProperty(key)) {
                      return (
                        <div
                          key={key}
                          className="d-flex justify-content-between align-items-center mb-2"
                        >
                          <span>
                            <strong>{key}:</strong> {renderValue(offer[key])}
                          </span>
                          <img
                            src={require("../assets/icons/question.png")}
                            alt="question icon"
                            style={{ width: "20px", height: "20px", marginLeft: "8px" }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="seller-net-footer">
          <Button color="secondary" onClick={hideModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Save Estimate Modal */}
      {/* <Modal isOpen={saveEstimateModal} toggle={toggleSaveEstimateModal}>
        <ModalHeader toggle={toggleSaveEstimateModal}>
          {selectedEstimate ? "Update Estimate" : "Save Estimate"}
        </ModalHeader>
        <ModalBody>
          <p>
            {selectedEstimate
              ? "Update this estimate with the new values and keep track of changes."
              : "Save this estimate for future reference and easy comparison. This allows you to revisit your projected net proceeds, closing costs, and other key financial details at any time."}
          </p>
          <FormGroup>
            <Label>Estimate Name</Label>
            <Input
              type="text"
              placeholder="Enter estimate name"
              value={estimateName}
              onChange={(e) => setEstimateName(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleSaveEstimateModal}>Cancel</Button>
          <Button color="primary" onClick={handleSaveEstimate} disabled={savingEstimate}>
            {savingEstimate ? "Saving..." : selectedEstimate ? "Update Estimate" : "Save Estimate"}
          </Button>
        </ModalFooter>
      </Modal> */}

{/* 
      <Modal isOpen={closingCostsEditConfirmation} toggle={() => setClosingCostsEditConfirmation(false)}>
        <ModalHeader toggle={() => setClosingCostsEditConfirmation(false)}>Edit Closing Costs</ModalHeader>
        <ModalBody>
          <div className="EstimateContainer">
            <p className="edit-closing-costs-description">
              You have updated the closing costs. Would you like to apply these changes only for this estimate or 
              for all future estimates to keep them consistent?
            </p>
            <div className="edit-closing-costs-confirmation-btns">
              <div>
                <Button className="currentestimatebtn" onClick={() => {
                  setSaveClosingCostsForAll(false);
                  setClosingCostsEditConfirmation(false);
                }}>
                  For this estimate only
                </Button>
              </div>
              <div>
                <Button className="allestimatebtn" onClick={() => {
                  setSaveClosingCostsForAll(true);
                  setClosingCostsEditConfirmation(false);
                }}>
                  For all future estimates
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </>
  );
};

export default RequestChangeModal;